.reset-psw {
    background: var(--bs-primary-hover);
    padding: 1rem;
    height: 100%;
}

.reset-psw__list {
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: white;
    border: 1px solid var(--c-border);
    box-shadow: 0 0 10px hsla(190, 49%, 33%, 0.025);
    border-radius: .25rem;
    padding: 2.5rem 1.5rem;
}

.reset-psw__item {
    margin-bottom: 1.5rem;
}

.reset-psw__item ion-label {
    text-transform: uppercase;
    color: var(--bs-primary);
    font-weight: bold;
    margin-left: .2rem;
}

.reset-psw__item ion-input {
    appearance: none;
    border: 1px solid var(--c-border);
    border-radius: .5rem;
    padding: 1rem;
    height: 3rem;
    background-color: var(--c-white);
    width: 100%;
    margin-top: .5rem;
    box-shadow: 0 10px 10px hsla(190, 49%, 33%, 0.025);
}

.reset-psw__item ion-input input {
    padding: 0 1rem!important;
}

.reset-psw__button {
    color: var(--c-white);
    text-transform: uppercase;
    max-width: none;
    padding: 0 2rem;
    height: 3rem;
    background-color: var(--bs-primary);
    border-radius: .5rem;
    margin: 1rem auto 0!important;
}

.reset-psw__message {
    text-align: center;
    color: var(--bs-primary);
    margin-top: 1rem;
    font-size: 0.9rem;
}
