.status_field {
    font-size: .9rem;

    display: inline-block;
    margin-left: 1rem;
    padding: .25rem 1rem;

    border: var(--border);
    border-radius: 20rem;

    align-self: center;
    flex-shrink: 0;

    &--button{
        flex-shrink: 0;
        align-self: stretch;

        margin-left: 1rem;
        white-space: nowrap;
    }

    &-0, &-2 {
        // PENDIENTE, PENDIENTE DE MI FIRMA
        color: var(--bs-secondary);
        border-color: var(--bs-secondary-t-60);
        background-color: var(--bs-secondary-t-015);
    }
    
    &-1 {
        // PENDIENTE ENCARGADO/RESPONSABLE
        color: var(--color-orange);
        border-color: var(--color-orange-t-60);
        background-color: var(--color-orange-t-015);
    }
    
    &-3 {
        // FIRMADO
        color: var(--color-green);
        border-color: var(--color-green-t-60);
        background-color: var(--color-green-t-015);
    }
}

.status_field--button::part(native), .prevention-table__item::part(native){
    font-size: .9rem;
    text-transform: uppercase;
    
    border: 1px solid var(--bs-primary-t-025);
    border-radius: 0.25rem;
    
    width: 100%;
    min-height: 0;
    height: 100%;
    padding: .25rem 0 .25rem 1rem;

    color: var(--bs-primary);
    background-color: var(--bs-primary-selected);
    transition: background-color .3s ease;
}

.prevention {
    padding: var(--padding);
    padding-bottom: 5rem;
    min-height: calc(100vh - 56.59px);

    &-table{

        &__content{
            width: 100%;
            padding: .5rem 0;

            p{
                display: block;
            }

            & .status_field{
                margin: 0;
            }
        }

        &__item::part(native){
            text-align: center;
            margin-left: 0;
        }

        .dataTable &__name{
            padding-left: 0;
            padding-right: 0;
            padding: 0;
        }

        &__a{
            --background-hover: var(--bs-primary-selected);
            --background-hover-opacity: .75;
        }

        &__status{
            display: flex;
            justify-content: flex-end;
            margin-bottom: .5rem;

            & .status_field{
                margin-right: auto;
            }
        }

        &__text{
            margin: 0;
            padding: 0;
        }
        
        &__name--with-status &__text{
            border-bottom: var(--border);
            padding: 0 0 .5rem;
            margin: .5rem 0 .75rem;
        }

        &__a:hover .status_field--button::part(native){
            background-color: white;
        }

        &__a:hover .status_field--button:hover::part(native){
            background-color: var(--bs-primary-selected-hover);
        }
    }
}

.status_field--button::part(native):hover, .prevention-table__item::part(native):hover{
    background-color: var(--bs-primary-selected-hover);
}

.status_field--button ion-label, .prevention-table__item ion-label{
    margin: 0;
}

@media screen and (max-width: 700px){
    .status_field{
        order: 0;
        width: 100%;
        text-align: center;
        margin: 0 0 .75rem;

        &--button{
            order: 2;
            flex-grow: 1;
    
            display: block;
            width: 100%;
            
            text-align: center;
            margin-left: 0;
            margin-top: .75rem;

            .input-wrapper{
                justify-content: center;
            }
        }
    }

    .prevention{
        &-table{
            &__status{
                flex-direction: column;
            }
        }

        .file{
            flex-wrap: wrap;
            padding: .75rem;

            &__info{
                order: 1;
                padding: 0 .25rem;
            }

            &__icon{
                display: none;
            }
        }
    }
}