.cal{
    width: 100%;
    min-height: 93vh;
    display: flex;
    flex-flow: row nowrap;
}
.cal__wrap{
    width: 100%;
    margin: 2rem;
}
.cal__header{
    display: flex;
    user-select: none;

}
.cal__svg{
    cursor: pointer;
    background-color: var(--ion-color-primary);
    color: white;
    width: 2.5rem;
    height: 2.5rem;
    padding: .1rem;
    border-radius: .25rem;
}
.cal__button{
    transition: .1s ease all;
}
.cal__button:hover{
    transform: scale(1.05);
}
.cal__button:active{
    transform: scale(.95);
}
.cal__month{
    margin: auto;
    font-size: 2rem;
}
.cal__weekdays{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    border-radius: .5rem;
    background-color: var(--ion-color-primary);
    color: white;
    font-weight: 800;
    font-size: 1.25rem;
    margin: .5rem .25rem;
    
}
.cal__weekday{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: .5rem;
}
.cal__content{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    
}
.cal__day{
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding:  1rem;
    margin: .25rem;
    border-radius: .5rem;
    background: var(--bs-primary-x-light);
    transition: .2s ease all;
    height: 13vh;
    overflow: hidden;
    
}
.cal__day:hover{
    background-color: var(--bs-primary-light);
}
.cal__day--empty:hover{
    background: var(--bs-primary-x-light);
}
.event{
    background: var(--ion-color-primary);
    color: white;
    border-radius: .25rem;
    width: 100%;
    margin: .05rem 0;
    padding: .1rem;
    min-width: 8rem;
}
.event__title{
    
}
.cal__agenda{
    background-color: red;
    border: 20px solid red;
}

.rbc-calendar{
    min-height: 700px;
}

.rbc-toolbar{
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    /* margin: .5rem 5%; */
    margin: 1rem;
}

.rbc-toolbar .rbc-toolbar-label{
    font-size: 1.5rem;
    font-weight: 600;

    display: block;
    color: var(--bs-primary);
    text-transform: uppercase;
    line-height: 1.5;
}

@media screen and (max-width: 1200px){
    .rbc-toolbar{
        margin: .5rem 1rem 1rem;
    }

    .rbc-toolbar .rbc-toolbar-label{
        order: 0;
        width: 100%;
        margin-bottom: 1rem;
        border-bottom: 1.5px solid var(--c-border);
        padding: .75rem 0!important;
    }
    
    .rbc-btn-group{
        order: 1;
    
        display: flex;
        flex-flow: row nowrap;
    }
    
    .rbc-btn-group:first-child{
        order: 2;
    }
}

.rbc-off-range-bg{
    background-color: var(--bs-primary-selected);
    /* opacity: .25; */
}

.rbc-date-cell.rbc-now button{
    color: var(--bs-primary);
    font-weight: bold;
}

.rbc-btn-group button{
    box-shadow: none;
    border-radius: .25rem;
    margin: 0 .35rem 0 0;
    border: none;
    transition: .3s ease all;
    padding: .5rem;
    
    /* background-color: pink; */
    color: var(--bs-primary);
    border: 1.5px solid var(--c-border);
}

.rbc-btn-group button:last-child{
    margin-right:0;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active, .rbc-btn-group button:hover, .rbc-toolbar button.rbc-active:focus, .rbc-toolbar button:focus, .rbc-toolbar button:active:focus{
    color: white;
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
}

.rbc-day-slot.rbc-today .rbc-time-slot{
    border-top: none;
}
.rbc-day-slot.rbc-today .rbc-timeslot-group{
    border-bottom: 1px solid var(--bs-primary-medium);
}
.rbc-btn-group:first-child button:nth-child(3), .rbc-btn-group:first-child button:nth-child(2){
    font-size: 0;
}
.rbc-btn-group:first-child button:nth-child(2)::before{
    content:"❮";
    font-size: .8rem;
}
.rbc-btn-group:first-child button:nth-child(3)::before{
    content:"❯";
    font-size: .8rem;
}

.rbc-header{
    padding: .25rem;
    color: var(--bs-primary);
}

.rbc-day-bg + .rbc-day-bg, .rbc-month-row + .rbc-month-row, .rbc-header + .rbc-header, .rbc-month-view{
    border-color: var(--c-border);
}

.rbc-today{
    background-color: var(--bs-primary-selected);
    box-shadow: inset 0 0 0 1px var(--bs-primary);
}

.rbc-month-view, .rbc-time-view{
    border-left: none;
    border-right: none;
}

.rbc-off-range{
    color: var(--bs-primary);
    opacity: .4;
}

.rbc-date-cell.rbc-now button{
    padding: .25rem .15rem 0 0;
}

.rbc-time-view .rbc-today{
    background-color: var(--bs-primary-selected);
}

.rbc-time-slot{
    font-size: .8rem;
    display: flex;
    justify-content: center;
    align-items: center;

    color:var(--bs-primary);
}

.rbc-time-slot:empty{
    flex-grow: 0;
}

.rbc-timeslot-group{
    min-width: 50px;
}

.rbc-label.rbc-time-header-gutter{
    width: 50px!important;
    min-width: 50px!important;
    max-width: 50px!important;
}

.rbc-time-content > * + * > *, .rbc-time-header.rbc-overflowing, .rbc-time-view, .rbc-time-content{
    border-color: var(--c-border);
}

.rbc-day-slot .rbc-time-slot{
    border-color: var(--bs-primary-x-light);
}

.rbc-agenda-view{
    margin: var(--padding);
    margin-top: 0;
    margin-bottom: 0;
}

ion-popover{
    --width: 90%;
    --max-width: 500px;
    /* max-width: 500px;  */
    --box-shadow: 0 0 10px hsla(190, 49%, 33%, 0.025);
    --offset-x: 2.5%;
}

.popover__status{
    padding: .5rem;
    border-radius: .5rem;
    text-align: center;

    margin:0;
    margin-bottom: .75rem;
}

.popover__status.confirmed{
    background: var(--color-green);
    color: white;
}

.popover__status.refused{
    background: var(--bs-secondary);
    color: white;
}

.popover__status.programmed{
    background: var(--bs-primary);
    color: white;
}

ion-popover .notif__p{
    padding-bottom: .75rem;
    line-height: 1.5;
}

.rbc-agenda-view table.rbc-agenda-table{
    border: none;
    width: 100%;
    /* padding: var(--padding); */
}

.rbc-agenda-table{
    /* background-color: pink; */
    background-color: var(--bs-primary-selected);
    /* padding: 1rem; */
    border-radius: .5rem;
}

.rbc-agenda-table tr.programmed{
    --color-status : var(--bs-primary);
    --color-status-bkg : var(--bs-primary-t-60);
    --content-status: "Pendiente";
}
.rbc-agenda-table tr.refused{
    --color-status : var(--bs-secondary);
    --color-status-bkg : var(--bs-secondary-t-60);
    --content-status: "Rechazada";
}
.rbc-agenda-table tr.confirmed{
    --color-status : var(--color-green);
    --color-status-bkg : var(--color-green-t-60);
    --content-status: "Confirmada";
}

.rbc-agenda-table tr{
    margin: 1rem;

    display: flex!important;
    flex-wrap: wrap;
    justify-content: space-between;

    color: inherit!important;
    box-shadow: 0 0 10px hsla(190, 49%, 33%, 0.025);
    
    border: 1px solid var(--c-border)!important;
    background-color: white!important;

    border-left: .5rem solid var(--color-status)!important;
    pointer-events: none;
}

/* .rbc-agenda-table tr::before{
    content: var(--content-status);
    color: white;
    background-color: var(--color-status);
    width: 100%;
    margin: 1rem 1rem 0 1rem;
    padding: .5rem;
    text-align: center;
    display: block;
    border-radius: .25rem;
    font-size: .9rem;
} */

.rbc-agenda-table tr{
    margin: 0;
}

.rbc-agenda-table tr:nth-child(1n + 2){
    /* margin-top: 0; */
}

.rbc-agenda-view table.rbc-agenda-table tbody{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: .75rem;

    padding: var(--section-padding);
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td{
    padding: .75rem .25rem 1rem;
    order: 1;
    max-width: 100%;
    padding-left: 1.2rem;
    color: var(--bs-primary-medium);
    /* color: var(--color-status-bkg); */
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td{
    border: none;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td:last-child{
    width: 100%;
    order:0;
    padding: 1rem 0 .75rem 0.25rem;
    border-bottom: 1px solid var(--c-border);
    margin: 0 1rem;
    font-weight: bold;
    white-space: wrap;
    /* color: var(--color-status); */
    color: var(--ion-text-color);
}

.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell{
    margin-left: auto;
}

.rbc-agenda-table thead{
    display: none;
}

.rbc-row-segment .rbc-event-content{
    font-size: .8rem;
}

.rbc-row-segment{
    padding: .25rem .25rem 0;
}

.rbc-day-slot .rbc-event-content{
    font-size: .85rem;
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rbc-day-slot .rbc-event-label{
    font-size: .65rem;
}

.rbc-day-slot .rbc-event{
    display: flex!important;
    justify-content: center;
    flex-direction: column;
}

.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus{
    outline: 1px auto var(--bs-primary-t-60);
}

ion-popover.calendar::part(content) {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

ion-popover.calendar .notif__props{
    padding-bottom: 0;
}

.calendar__a *{
    text-decoration: none;
}

@media screen and (max-width: 1400px){
    .rbc-agenda-view table.rbc-agenda-table tbody{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 700px){
    .cal__wrap{
        margin: .5rem
    }

    .rbc-agenda-view table.rbc-agenda-table tbody{
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width: 550px){
    .cal__day{
        margin: .1rem;
        padding: 1rem .25rem;
    }
}

@media screen and (max-width: 400px){
    .rbc-btn-group{
        margin: 0 auto 0 0;
        flex-basis: 100%;
    }
    
    .rbc-btn-group:first-child{
        margin: .5rem auto 0 0;
    }

    .rbc-btn-group button{
        flex-grow: 1;
    }
}