.notifs{
    padding: var(--padding);
    padding-bottom: 5rem;
    color: #1a1a1a;
    min-height: calc(100vh - 56.59px);

    &__tabs{
        list-style: none;
        margin: 0;
        padding: 0;
    
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: .75rem;
        
        border-bottom: 1px solid var(--bs-primary-t-025);
        margin-bottom: 1rem;
        padding: 0 .5rem;
    }
    
    &__tab{
        width: 100%;

        &-button{
            width: 100%;
            color: var(--bs-primary);
            background-color: #2b717f26;
        
            font-size: .95rem;
            text-transform: uppercase;
            padding: .75rem 1rem;
        
            border-radius: .25rem .25rem 0 0;
            border-bottom: none;
            transition: all .3s ease;

            .active &{
                color: white;
                background-color: var(--bs-primary);
            }
        }

        &:not(.active):hover &-button{
            background-color: #2b717f34;
        }
    }
    
    &__column{
        display: none;

        &.active{
            display: block;
        }
    }
    
    &__head{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    
        margin-bottom: 1rem;
    
        border-radius: 0;
        border-bottom: 1px solid var(--bs-primary-t-025);
    }
    
    &__title{
        margin: 0 calc(var(--section-padding) / 2) var(--section-padding);
        padding: 0;
        font-size: .95rem;
    
        flex-grow: 1;
    
        display: flex;
        justify-content: center;
        align-items: center;
        
        border-radius: .25rem;
        background-color: var(--c-notifs-bkg, #2b717f26);
        color: var(--c-notifs-title, var(--bs-primary));
    
        transition: all .3s ease;

        &.active{
            background-color: var(--c-notifs-title, var(--bs-primary));
            color: rgba(255, 255, 255, 0.9);
        }
        
        &.accepted, &.received{
            --c-notifs-bkg: var(--color-green-t-015);
            --c-notifs-title: var(--color-green);
        }
        
        &.denied{
            --c-notifs-bkg: var(--bs-secondary-t-015);
            --c-notifs-title: var(--bs-secondary);
        }

        &-text{
            text-transform: uppercase;
            padding: .5rem 1rem;
            flex-grow: 1;
            text-align: center;
            pointer-events: none;
        }
        
        &-num{
            padding: .5rem .75rem;
            border-left: 1px solid var(--c-notifs-title, var(--bs-primary));
            border-radius: 0 .25rem .25rem 0;
            pointer-events: none;
        }
        
        &.active &-num{
            border-color: rgba(255, 255, 255, 0.5);
        }
    }
    
    &__body{
        padding: .75rem var(--section-padding);
        border-radius: .5rem;
        background-color: var(--bs-primary-selected);
        
        display: grid;
        grid-template-columns: 1fr;
        gap: .75rem;
        grid-auto-flow: row dense;

        & .sc-ion-buttons-ios-h, & .sc-ion-buttons-md-h{
            justify-content: center;
            padding: 0;
        }
    }

    &__svg{
        width: 1rem;
        height: 1rem;
        margin: .23em .75rem 0 .5rem;
        color: var(--bs-primary);
        flex-shrink: 0;
    }

    @media screen and (min-width: 800px) {
        &__title{
            max-width: 200px;
        }
    
        &__body{
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media screen and (min-width: 1700px){
        &__head{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
    
        &__body{
            grid-template-columns: repeat(3, 1fr);
        }
    
        &__column--two &__head, &__column--two &__body{
            grid-template-columns: repeat(2, 1fr);
        }
    
        &__title{
            max-width: none;
        }
    }
}

.notif{
    width: 100%;
    padding: .75rem;

    border-radius: .5rem;
    background-color: white;
    border: 1px solid var(--c-border);
    box-shadow: 0 0 10px hsla(190, 49%, 33%, 0.025);

    width: 100%;
    border-left: .5rem solid pink;

    &.inactive{
        display: none;
    }
    
    &.pending, &.sent{
        border-left-color: var(--bs-primary);
        order: 0;
    }
    
    &.accepted, &.received{
        border-left-color: var(--color-green);
        order: 1;
    }
    
    &.denied{
        border-left-color: var(--bs-secondary);
        order: 2;
    }

    &__date{
        margin:0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    
        padding: 0 .25rem .75rem;
        color: var(--bs-primary-medium);
    
        margin-bottom: 1rem;
        border-bottom: 1px solid var(--c-border);
    }
    
    &__date:last-child{
        margin-bottom: 0;
        margin-left: 1rem;
        border-bottom: none;
    }
    
    &__date span > span + span{
        margin-left: .5rem;
    }
    
    &__date ion-icon{
        margin-right: .25rem;
        vertical-align: text-top;
        margin-top: .75px;
    }
    
    &__title{
        font-size: 1rem;
        padding: 0 0 .5rem .25rem;
        margin-bottom: .75rem;
        border-bottom: 1px solid var(--c-border);
        font-weight: bold;
        color: #383838;
    
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        &-text{
            flex-basis: 80%;
            margin:0;
            order:1;

            & span{
                display: block;
                font-size: .95rem;
                font-weight: normal;
                color: var(--bs-primary-medium);
                margin: .25rem 0 .15rem;
            }
        }

        & ion-buttons{
            order:0;
        }

        & ion-button{
            --padding-start: .15rem;
            --padding-end: .15rem;

            & svg{
                width: 100%;
                height: 100%;
            }
        }

        @media screen and (min-width: 800px){
            font-size: 1.15rem;
        }

    }
    
    &__refused{
        font-size: .9rem;
        font-weight: normal;
        color: var(--bs-primary-medium);
        margin: 0.25rem .5rem 0.15rem 0;
    }
    
    &__props{
        padding-bottom: .75rem;
    }
    
    &__prop{
        line-height: 1.5;
        color:#383838;
    
        padding: 0 .25rem;

        & ion-icon{
            align-self: center;
            margin-right: .25rem;
            color: var(--bs-primary);
        }
        
        &:nth-child(1n + 2){
            margin-top: .5rem;
        }
    }
    
    &__buttons{
        margin-bottom: .25rem;
    }
    
    &__button{
        padding: .5rem;
        border-radius: .5rem;
        text-align: center;
    
        margin-bottom: 1rem;
    }
    
    &.accepted &__button{
        background: var(--color-green);
        color: white;
    }
    
    &.denied &__button{
        background: var(--bs-secondary);
        color: white;
    }
    
    &.pending &__button{
        background: var(--bs-primary);
        color: white;
    }

    &__p{
        margin:0;
        padding:0;
    }
    
    &__span{
        font-weight: bold;
        margin-right: .5rem;

        &--block{
            display: block;
        }
    }

    &__open{
        text-transform: uppercase;
        margin-top: 1rem;
        padding: .75rem;
        border-radius: .25rem;
        border: 1px solid var(--bs-primary-t-025);
        width: 100%;
    
        color: var(--bs-primary);
        background-color: var(--bs-primary-selected);
    }
    
    &__open:hover{
        background-color: var(--bs-primary-selected-hover);
    }

    @media screen and (min-width: 800px) {
        &__title {
            & ion-buttons{
                order:1;
            }

            &-text{
                flex-basis: 80%;
                order:0;
            }
        }
    }

    @media screen and (min-width: 1700px){
        &.pending, &.sent{
            grid-column: 1;
        }
        
        &.accepted, &.received{
            grid-column: 2;
        }
        
        &.denied{
            grid-column: 3;
        }
    }

}

.request__button{
    &.request__button{
        height: 2rem;
        margin: 0;
        border-radius: .25rem;
        color: var(--color-green);
        background-color: var(--color-green-t-015);
        --background: transparent;
        --box-shadow: none;

        display: flex;
        justify-content: center;
        align-items: center;

        flex-grow: 1;
        width: 40%;
        margin-bottom: .5rem;
    }

    &.button-disabled{
        opacity: 1;
    }
    
    &.cancel{
        background-color: var(--bs-secondary-t-015);
        color: var(--bs-secondary);
    }
    
    &.icon{
        background-color: #2b717f26;
        color: var(--bs-primary);
    }
    
    &:nth-child(1n + 2){
        margin-left: .5rem;
    }
    
    &:first-of-type{
        margin-left: 0;
    }

    @media screen and (min-width: 800px) {
        &{
            margin: 0;
        }
    
        &:first-of-type{
            margin-left: 0;
            flex-grow: 0;
            width: 2rem;
        }
    }
} 

.sc-ion-alert-md-h{
    --max-width: 550px;
}

.file.no-hover{
    pointer-events: none;
}