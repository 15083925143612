.report{
    width: 100%;
    padding: .75rem;
    border-radius: .5rem;
    background-color: white;
    border: 1px solid var(--c-border);
    box-shadow: 0 0 10px hsla(190, 49%, 33%, 0.025);

    & ion-icon{
        pointer-events: none;
    }

    &__title{
        font-size: 1.15rem;
        padding: 0 0 .65rem .25rem;
        margin-bottom: .75rem;
        border-bottom: 1px solid var(--c-border);
        font-weight: bold;
    
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        & p{
            margin:0;
        }
    }
    
    &__date{
        font-size: .9rem;
        font-weight: normal;
    
        margin:0;
        margin-top: .15rem;
    
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--bs-primary-medium);

        & ion-icon{
            margin-right: .25rem;
        }
    }
    
    &__button{
        width: 2.35rem;
        height: 2.35rem;
        font-size: 1.5rem;
    
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-start;
    
        border: var(--border);
        border-color: var(--bs-primary-medium-light);
        border-radius: .25rem;
    
        cursor: pointer;
        transition: background-color .3s ease;

        &:hover{
            background-color: var(--bs-primary-selected);
        }
    }
    
    &__prop{
        margin: .25rem;
        font-size: .95rem;
        line-height: 1.4;

        & + &{
            margin-top: .75rem;
        }

        &--advice + &--advice{
            margin-top: 0;
        }

        &--advice::before{
            content:"- ";
            margin-right:.25rem;
        }
        
        &--title{
            display: block;
            font-weight: bold;

            .aptitudes &{
                margin: 0 .25rem;
            }
        }
        
        &--value{
            display: inline;
        }
    }

    &__results{
        background-color: var(--bs-primary-selected);
        border-radius: .25rem;
        padding: .75rem;
    }
    
    &__results + &__file{
        margin-top: .75rem;
    }
    
    &__result{
        margin: 0;

        & span{
            display: block;
            font-weight: bold;
        }
    }
    
    &__prop + .file, &__results + &__results, & + &, &__result + &__result{
        margin-top: .75rem;
    }
    
    &__see-more{
        margin-left: .5rem;
        text-decoration: underline;
    }
}

.aptitudes{
    margin: .25rem 0;
}

.file{
    font-size: .95rem;

    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    padding: .5rem;
    border: var(--border);
    border-color: var(--c-border);
    border-radius: .25rem;

    cursor: pointer;
    background-color: white;
    transition: background-color .3s ease;

    color: var(--bs-primary);

    &:hover{
        background-color: var(--bs-primary-selected);
    }
    
    & + &{
        margin-top: .5rem;
    }

    &--no-hover:hover{
        background-color: white;
        cursor: auto;
    }
    
    &__icon{
        flex-shrink: 0;
    
        font-size: 1.25rem;
        border-right: var(--border);
        border-color: var(--c-border);
        padding-right: .5rem;
        margin-right: .5rem;
    
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    &__info{
        margin: 0;
        flex-grow: 1;
        align-self: center;
    
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    
        line-height: 1.25;
        overflow: hidden;

        @media screen and ( max-width: 500px ){
            &{
               flex-direction: column;
            }
        }
    }
    
    &__type{
        color: var(--ion-text-color);
        flex-grow: 1;
        flex-shrink: 0;
        font-weight: bold;
    }
    
    &__name{
        opacity: .75;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: .95rem;
        text-decoration: underline;

        @media screen and (max-width: 800px) {
            width: 100%;
        }
    }

    &__date{
        color: var(--bs-primary-medium);
        font-weight: normal;
        margin-right: 1rem;

        @media screen and (min-width: 800px) {
            &::before{
                content: '|';
                margin-left: .25rem;
            }
        }

        @media screen and (max-width: 799px) {
            width: 100%;
            display: block;
            border-bottom: var(--border);
            border-color: var(--c-border);
            padding-bottom: .35rem;
            margin-bottom: .35rem;
        }
    }
}