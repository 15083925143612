.vigil{
    padding: var(--padding);

    &__body{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: flex-start;
    }
    
    &__column{
        border-radius: .5rem;
        padding: 0.5rem 0;
        width: 100%;

        & + &{
            padding-left: 1rem;
        }
    }

    @media screen and (max-width: 720px) {
        &__body{
            flex-direction: column;
        }
    
        &__column + &__column{
            padding-left: 0;
            padding-top: 1rem;
        }
    }
}

.reports__title{
    font-size: 1.5rem;
    font-weight: bold;
    
    padding: 0 .75rem .25rem;
    margin-bottom: 1rem;

    border-bottom: var(--border);
    border-color: var(--c-border);
}

.reports__body{
    padding: .75rem var(--section-padding);
    border-radius: .5rem;
    background-color: var(--bs-primary-selected);
}
