ion-button{
    font-size: .95rem;
    margin-top: 2rem;
    height: 3rem;

    max-width: 50%;
    margin: 2rem auto 0;

    display: block;

    flex-shrink: 0;
}

button{
    background-color: transparent;
}

.tabs{
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;

    list-style: none;
    margin: 0 0 2rem;
    padding: 0;

    border-bottom: 1px solid var(--bs-primary-t-025);

    @media screen and ( max-width: 700px ) {
        gap: .5rem;
        border: none;
    }

    &__li{
        flex-grow: 1;
    }
    
    &__button{
        font-size: .95rem;
        text-transform: uppercase;
        padding: 1rem 2rem;
    
        color: var(--ion-text-color);
    
        width: 100%;
        white-space: nowrap;
        background-color: transparent;
        border-radius: .25rem .25rem 0 0;

        &:hover{
            background-color: var(--bs-primary-selected);
        }
        
        .active &{
            color: var(--bs-primary);
            background-color: var(--bs-primary-selected);
            box-shadow: inset 0 -3px 0 0 var(--bs-primary-t-025);
        }
        
        & ion-icon{
            vertical-align: text-bottom;
            margin-right: .5rem;
        }

        @media screen and ( max-width: 700px ) {
            color: var(--bs-primary);
            background-color: var(--bs-primary-selected);
            border-radius: .25rem;
            padding: .75rem 1rem;

            .active &{
                color: white;
                background-color: var(--bs-primary);
                box-shadow: inset 0 -3px 0 0 var(--bs-primary-t-025);
            }

            & ion-icon{
                display: none;
            }
        }
    }
    
    &__wrapper{
        display: none;

        &.active{
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(min(600px, 100%), 1fr));
            gap: 1.5rem;
        }
    }

    &__title{
        font-size: 1.25rem;
        font-weight: 500;
        padding: 0 0.75rem 0.25rem;
        margin-bottom: 1rem;
        border-bottom: var(--border);
        border-color: var(--c-border);
    }
}

.data-list{
    margin: 0;
    padding: 0 var(--section-padding);
    border-radius: 0.5rem;
    // background-color: var(--bs-primary-selected);
}

.icon-select{
    margin: 0;
    padding: 0;
    list-style: none;

    // display: flex;
    // justify-content: flex-start;
    // align-items: stretch;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: row dense;
    gap: .5rem;

    width: 100%;

    input{
        display: none;
    }

    &__li{
        &--medium{
            grid-column: span 2;
        }

        &--long{
            grid-column: span 3;
        }

        &--huge, &--wrap{
            grid-column: span 4;
        }
    }

    &__styled{
        width: 100%;
        color: var(--bs-primary);

        border-radius: .25rem;
        background-color: var(--bs-primary-selected);

        display: flex;
        justify-content: flex-start;
        align-items: stretch;

        &:hover{
            background-color: var(--bs-primary-selected-hover);
            cursor: pointer;

            span{
                background-color: var(--bs-primary-selected);
                cursor: pointer;
            }
        }

        &-icon{
            font-size: 1.1rem;
            line-height: 0;
            flex-shrink: 0;
    
            border: var(--border);
            border-color: var(--c-border);    
            border-radius: .25rem 0 0 .25rem;
    
            padding: .4rem;

            display: flex;
            justify-content: center;
            align-items: center;
        }
    
        &-name{
            flex-shrink: 0;
            flex-grow: 1;
            line-height: 0;
            padding: .25rem .5rem;
            white-space: nowrap;
    
            border: var(--border);
            border-left: none;
            border-color: var(--c-border);
            border-radius: 0 .25rem .25rem 0;
    
            display: flex;
            justify-content: center;
            align-items: center;
    
            background-color: white;

            .icon-select__li--wrap &{
                white-space: normal;
                max-width: 100%;
                line-height: 1.2;
                flex-shrink: 1;
            }
        }

        input:checked + & &-icon{
            color: white;
            background-color: var(--bs-primary);
            border-color: var(--bs-primary);
        }

        input:checked + & &-name{
            border-color: var(--bs-primary-medium);
        }
    }

}

.dataTable{
    width: 100%;
    padding: 0 var(--section-padding);
    border-collapse: separate !important;

    thead{
        background-color: var(--bs-primary-selected);
    }
    
    tr > *:first-child{
        border-left: var(--border);
    }
    
    th:first-child{
        border-radius: .25rem 0 0 0;
    }

    tr > *:last-child{
        border-right: var(--border);
    }
    
    th:last-child{
        border-radius: 0 .25rem 0 0;
    }

    tr:last-child td{
        border-bottom: var(--border);
    }

    th, td{
        border-top: var(--border);
        border-color: var(--c-border);
        padding: .65rem 1rem;

        font-size: .95rem;
        line-height: 1.2;
    }

    th{
        color: var(--bs-primary);
        text-align: left;
    }

    &__id{
        width: 75px;
    }

}

ion-menu.md ion-item ion-icon{
    color: var(--bs-primary-light);
}

ion-menu.md ion-menu-toggle:last-child ion-item ion-icon{
    color: var(--bs-secondary);
}

.picker-wrapper.sc-ion-picker-md{
    --border-color: var(--bs-primary-light);
    --max-width: 100%;
}

.picker-toolbar.sc-ion-picker-md{
    border-bottom: 1px solid var(--bs-primary-light);
}

.picker-prefix, .picker-suffix, .picker-opt.picker-opt-selected{
    background: var(--bs-primary-selected);
    border: 1.5px solid var(--bs-primary-light);
    border-inline: none;
}

.picker-col{
    padding: 0;
}

ion-modal {
    --height: auto;
    --max-height: 90%;
    --overflow: auto;
}

ion-modal.modal-default:not(.overlay-hidden) ~ ion-modal.modal-default{
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal .ion-page{
    position: static!important;
    contain: initial!important;
}

.item-detail-icon{
    display: none!important;
}

.alert-button.sc-ion-alert-md.alert-button-role-cancel{
    color: var(--bs-secondary);
}

.button-base{
    padding: .75rem 1rem;
    color: var(--bs-primary);
    background-color: var(--bs-primary-selected);
    border-radius: .25rem;
    text-transform: uppercase;
    font-size: .9rem;
    border: 1px solid var(--bs-primary-t-025);
}

.button-base:hover{
    background-color: var(--bs-primary-selected-hover);
}

.button-base.solid{
    color: white;
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
}

.button-base ion-icon{
    vertical-align: text-bottom;
    margin-right: .5rem;
    font-size: 1.2em;
}

.button-base.solid:hover{
    border-color: var(--bs-primary-medium);
    background-color: var(--bs-primary-medium);
}

.fixed-button-container{
    background-color: white;

    position: fixed;
    inset: auto 0 0;
    padding: 1rem;
    z-index: 999;
}

.fixed-button-container .button-base{
    width: 100%;
}

form ion-label, .fake-input ion-label{
    font-size: .9rem;
    display: block;
    padding: .75rem 0 .25rem .25rem;
    color: var(--bs-primary);
    --color: var(--bs-primary);
}

ion-label span{
    text-transform:capitalize;
    font-weight: normal;
    color: var(--bs-primary-medium);
    margin-left: .2rem;
}

.label-stacked.sc-ion-label-md-h, .label-stacked.sc-ion-label-ios-h{
    font-size: .9rem;
    // margin-bottom: .35rem;

    transform: translateY(0) scale(1);
}

ion-input{
    --placeholder-opacity: .5;
}

.native-input.sc-ion-input-ios, .native-input.sc-ion-input-md, .native-textarea.sc-ion-textarea-ios, .native-textarea.sc-ion-textarea-md{
    padding: .75rem;
    font-size: .9rem;
    
    border: var(--border);
    border-radius: .25rem;
    background-color: white;
}

.input-bottom.sc-ion-input-md{
    --border-width: 0;
    position: absolute;
    top: -1.35rem;
    right: 0.25rem;
}

ion-select{
    border: var(--border);
    border-radius: .25rem;

    --padding-top: .65rem;
    --padding-bottom: .65rem;
    --padding-start: .65rem;
    --padding-end: .65rem;

    font-size: .9rem;
    --placeholder-opacity: .5;
}

ion-select::part(icon) {
    transform: translate3d(0, 0, 0);
}

ion-textarea{
    margin: 0;
    --placeholder-opacity: .5;
}

.native-wrapper.sc-ion-textarea-md textarea.sc-ion-textarea-md{
    border: var(--border);
    padding: .75rem;
    border-radius: .25rem;
}

.textarea-bottom.sc-ion-textarea-md{
    --border-width: 0;
    position: absolute;
    top: -1.35rem;
    right: 0.25rem;
}

.modal-form{
    &__ul{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    
    // &__item{
    //     --padding-start: 0;
    //     --padding-end: 0;
    //     --inner-padding-end: 0;
    //     --inner-border-width: 0;
    //     --border-width:0;
    //     --background: transparent;
    // }

    &__select{
        --padding-start: .75rem;
        --padding-end: .75rem;
        --padding-top: .75rem;
        --padding-bottom: .75rem;
        min-height: auto!important;
    }

    &__select::part(icon){
        top: 0;
    }

    &__select::part(placeholder){
        opacity: .55!important;
    }
    
    &__add-files, &__take-photo{
        display: block;
        // margin: 0 auto;
        width: 100%;
    }

    &__take-photo{
        margin-bottom: .5rem;
    }

    &__submit{
        margin-top: 2rem;
        width: 100%;
    }
}

.fake-input{
    ion-note{
        border: var(--border);
        border-radius: .25rem;

        font-size: .9rem;
        text-align: inherit;

        color: inherit;
        opacity: 1;

        padding: .75rem;
        margin: 0;

        background-color: var(--ion-color-light);
    }

    &__button{
        margin-top: 2rem;
        width: 100%;
    }
}
  
@media screen and (min-width: 700px) {
    .tabs__li{
        flex-grow: 0;
    }

    .fixed-button-container{
        background-color: transparent;

        position: fixed;
        inset: auto 0 0 auto;
        padding: 2rem;

        pointer-events: none;
    }

    .fixed-button-container--notif{
        background-color: transparent;

        position: static;
        padding: 0 .5rem .75rem;
        margin-bottom: .75rem;

        pointer-events: none;
        border-bottom: var(--border);
    }

    .fixed-button-container .button-base{
        pointer-events: auto;
        padding: .5rem 1rem;
    }

    .fixed-button-container--notif .button-base{
        width: auto;
    }
}

@media screen and (min-width: 900px) {
    .picker-wrapper.sc-ion-picker-md{
        top: 0;
        --border-radius: .25rem;
        --border-width: 1.5px;
        --max-width: 500px;
    }
}