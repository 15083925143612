/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-default-font: "Ubuntu";
  --ion-font-family : "Ubuntu";

  --ion-color-primary             : #2b717f;
  --ion-color-primary-rgb         : 43, 113, 127;
  --ion-color-primary-contrast    : #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade       : #266370;
  --ion-color-primary-tint        : #407f8c;
  --ion-text-color                : #383838;
  
  --bs-primary-500              : #124650;
  --bs-primary                  : #2b717f;
  --bs-primary-t-60             : #2b707d99;
  --bs-primary-t-025            : #2b717f40;
  --bs-primary-selected-hover   : #e8f5f5;
  --bs-primary-selected         : #f4f9f9;
  --bs-primary-hover            : #f3f5f5;
  --bs-primary-light            : #c0e3e7;  
  --bs-primary-medium-light     : #b1cfd5;  
  --bs-primary-medium           : #6ca9b4;  
  --bs-primary-light-transparent: #2b717f99;  
  --bs-primary-x-light          : #f1fbfc;  

  --bs-border-radius: .25rem;

  --bs-secondary-500  :#af351a;
  --bs-secondary      :#e17166;
  --bs-secondary-t-60 :#e1716699;
  --bs-secondary-t-025:#e1716640;
  --bs-secondary-t-015:#e1716626;  

  --color-green-500  : #2f5a1f;
  --color-green      : #7cb565;
  --color-green-t-60 : #71BF6399;
  --color-green-t-025: #71BF6340;
  --color-green-t-015: #71BF6326;

  --color-orange-500  : #816404;
  --color-orange      : #cd9e01;
  --color-orange-t-60 : #e9b60b99;
  --color-orange-t-025: #e9b60b40;
  --color-orange-t-015: #e9b60b26;  

  --c-border: #bedbe1;

  
  /** secondary **/
  --ion-color-secondary             : #3dc2ff;
  --ion-color-secondary-rgb         : 61, 194, 255;
  --ion-color-secondary-contrast    : #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade       : #36abe0;
  --ion-color-secondary-tint        : #50c8ff;

  /** tertiary **/
  --ion-color-tertiary             : #5260ff;
  --ion-color-tertiary-rgb         : 82, 96, 255;
  --ion-color-tertiary-contrast    : #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade       : #4854e0;
  --ion-color-tertiary-tint        : #6370ff;

  /** success **/
  --ion-color-success             : #2dd36f;
  --ion-color-success-rgb         : 45, 211, 111;
  --ion-color-success-contrast    : #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade       : #28ba62;
  --ion-color-success-tint        : #42d77d;

  /** warning **/
  --ion-color-warning             : #ffc409;
  --ion-color-warning-rgb         : 255, 196, 9;
  --ion-color-warning-contrast    : #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade       : #e0ac08;
  --ion-color-warning-tint        : #ffca22;

  /** danger **/
  --ion-color-danger             : #eb445a;
  --ion-color-danger-rgb         : 235, 68, 90;
  --ion-color-danger-contrast    : #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade       : #cf3c4f;
  --ion-color-danger-tint        : #ed576b;

  /** dark **/
  --ion-color-dark             : #222428;
  --ion-color-dark-rgb         : 34, 36, 40;
  --ion-color-dark-contrast    : #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade       : #1e2023;
  --ion-color-dark-tint        : #383a3e;

  /** medium **/
  --ion-color-medium             : #92949c;
  --ion-color-medium-rgb         : 146, 148, 156;
  --ion-color-medium-contrast    : #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade       : #808289;
  --ion-color-medium-tint        : #9d9fa6;

  /** light **/
  --ion-color-light             : #f4f5f8;
  --ion-color-light-rgb         : 244, 245, 248;
  --ion-color-light-contrast    : #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade       : #d7d8da;
  --ion-color-light-tint        : #f5f6f9;

  --c-white: white;

  --padding: 1.25rem 3%;
  --section-padding: .5rem;

  --border: 1px solid var(--bs-primary-t-025);
}

@media screen and (min-width: 700px) {
  :root{
    --padding: 1.25rem;
    --section-padding: .75rem;
  }
}